html {
	font-size: 62.5%;
}

@font-face {
    font-family: 'myanmar';
    src: local('myanmar'), url(../assets/fonts/Cherry_Unicode.ttf) format('truetype');
}

@font-face {
    font-family: 'zawgyi';
    src: local('zawgyi'), url(../assets/fonts/NotoSansMyanmar.ttc) format('ttc');
    font-weight: normal;
}


body{
    padding: 0 !important;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}



body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.scrollable-body{
    overflow-y: scroll;
    height: calc(100svh - 82px);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.scrollable-body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
/* :root {
    --vh: calc(var(--vh, 1vh) * 100);
  } */
.scrollable-parlay-body{
    overflow-y: auto;
    height: calc(100svh - 158px);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.scrollable-parlay-body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.scrollable-bodyOver{
    overflow-y: auto;
    height: calc(100svh - 184px);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.scrollable-bodyOver::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.logo-style{
    font-family: 'Courgette', cursive;
}


.body-color{
    background-color: #010625;
}

.white{
    color: white;
}
.black{
    color: black;
}
.header-color{
    background-color: #021928;
}

.footer-color{
    background-color: #021928;
}
#input-amount-to-bet::placeholder{
    color: rgb(140, 137, 137);
    font-size: 14px;
    font-weight: normal;
}
#news-search::placeholder{
    color: white;
    /* color: rgb(140, 137, 137); */

    font-size: 16px;
    font-weight: normal;
}

.search-league{
    background-color: #0A324D;
    border: lightslategray;
    caret-color: white;
    color: whitesmoke
}
.search-league::placeholder{
    color: white
}
.search-league::cursor{
    color: white
}

.header-filter-league{
    padding: 0 !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 16px;
}
h4 {
    font-size: 14px;

}
h5 {
    font-size: 12px;
    color: "rgb(140, 137, 137)";
}
h6 {
    font-size: 10px;
    color: "rgb(140, 137, 137)";
}
.month-popover{
    transform: translateX(-50%);
    transition: transform 2s ease;
}
.month-popover-slide{
    transform: translateX(-50%);
    transition: transform 2s ease;
    transform: none;
}
.custom-input .ant-input{
    background-color: rgb(10, 50, 77);
    color: white;
    padding: 0.6rem 0 !important;
}

.custom-input .ant-select-single.ant-select-lg.ant-select-open .ant-select-selection-item {
    color: white; /* Change this to the desired text color */
  }

.custom-input .ant-select-selector{
    background-color: rgb(10, 50, 77) !important;
    border-color: white !important;
    color: white !important;
}

.custom-input .ant-select-arrow{
    color: white;
}
.ant-select-item-option-content {
    color: gray !important;
}


.custom-input .ant-input-affix-wrapper:hover{
    border-color: #87CEEB;
}
.custom-input .ant-input-affix-wrapper-focused{
    border-color: #87CEEB;
}
.custom-input .ant-form-item-feedback-icon-success{
    color: #87CEEB
}
.custom-input .ant-form-item-explain-error{
    font-size: 1.2rem;
}
.custom-input .ant-input-password-icon{
  color: grey !important
}
.custom-input .ant-form-item-required{
    color: white !important;
}
.custom-input label{
    color: white !important;
}

.custom-input .anticon-lock{
    color: grey;
    padding-right: 1rem;
}

.custom-input .anticon-dollar{
    color: grey;
    padding-right: 1rem;
}

.custom-input .anticon-user{
    color: grey;
    padding-right: 1rem;
}

.custom-input .anticon-phone{
    color: grey;
    padding-right: 1rem;
}

.custom-input .anticon-credit-card{
    color: grey;
    padding-right: 1rem;
}

.custom-input .anticon-account-book{
    color: grey;
    padding-right: 1rem;
}

.bet-amount .ant-form-item{
    margin-bottom: 0 !important;
}
.bet-amount .ant-form-item-explain-error{
    font-size: 1rem;
}
.bet-amount .ant-input::placeholder {
    color: grey;

}
.bet-amount .ant-input{
    background-color: black;
}

.running-text-container {
    overflow: hidden;
    white-space: nowrap;
}

.running-text {
    animation: runText 10s linear infinite;
}

@keyframes runText {
    0% {
        transform: translateX(110%);
    }

    100% {
        transform: translateX(-200%);
    }
}

 /* Set the width and height of the scrollbar track */
 ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  
  /* Style the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: gray; /* Set the color of the thumb */
    border-radius: 4px; /* Round the corners of the thumb */
  }
  
  /* Change the appearance on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: gray; /* Change color on hover */
  }
  
  /* Style the scrollbar track */
  ::-webkit-scrollbar-track {
    background-color: #1C1917; /* Set the color of the track */
  }
  
  /* Style the scrollbar corner */
  ::-webkit-scrollbar-corner {
    background-color: #1C1917; /* Set the color of the corner */
  }